import React, { memo, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import loadable from '@loadable/component';
import './style.scss';
import { getModelUrl } from '../../utils/helpers';
const GatsbyImage = loadable(() => import('./../common/GatsbyImage'));
const CustomLink = loadable(() => import('./../common/CustomLink'));

const PromotionBlock = memo(({ slice, expired }) => {
  const {
    primary: { title, category, sort },
    items,
    slice_type,
  } = slice;

  const [state, setState] = useState([]);

  useEffect(() => {
    if (expired) {
      let blocks = items;
      if (expired?.length) blocks = items.filter((item) => !expired.includes(item.text));

      if (!sort || sort === 'A-Z') {
        blocks = blocks.sort((a, b) => {
          const aName = a.brand?.document ? a.brand?.document?.data?.name.toUpperCase() : '';
          const bName = b.brand?.document ? b.brand?.document?.data?.name.toUpperCase() : '';
          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        });
      }

      setState(blocks);
    }
  }, [expired]);

  return (
    <section className={`${slice_type}`}>
      {(title || category?.document?.data?.name) && (
        <div className={`${slice_type}__title -title`}>
          <h3>{title || `${category?.document?.data?.name} Promotions`} </h3>
        </div>
      )}

      <div className={`${slice_type}__inner`}>
        <div className="row">
          {state.map(({ image, lifestyle_image, text, brand, product_url, title }, i) => (
            <div className="col-lg-4 col-md-6">
              <article key={i} className={`${slice_type}__item promotion-item`}>
                <div className="promotion-item__image">
                  <CustomLink link={brand}>
                    <GatsbyImage image={image} alt={image?.alt} />
                    {lifestyle_image?.gatsbyImageData && (
                      <GatsbyImage
                        className="promotion-item__image--hover d-none d-md-block"
                        image={lifestyle_image}
                        alt={lifestyle_image?.alt}
                      />
                    )}
                  </CustomLink>
                </div>
                <div className="promotion-item__inner">
                  <div className="promotion-item__title">
                    <CustomLink className="-link-primary -no-underline" link={brand}>
                      <h3 className="m-0">{title || brand?.document?.data?.name}</h3>
                    </CustomLink>
                  </div>
                  <div className="promotion-item__sort-description">{text}</div>
                  <div className="promotion-item__read-more">
                    <CustomLink
                      className="-link-primary -underline"
                      link={brand}
                      text="Shop This"
                    />
                  </div>
                  {product_url && product_url.handle ? (
                    <div className="promotion-item__learn-more">
                      <Link to={getModelUrl('buy', product_url.handle)} title={product_url.title}>
                        Learn More
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default PromotionBlock;

export const query = graphql`
  fragment PromotionsPageDataBodyPromotionsBlock on PrismicPromotionsPageDataBodyPromotionsBlock {
    primary {
      title
      sort
      category {
        uid
        document {
          ... on PrismicCategory {
            data {
              name
            }
          }
        }
      }
    }
    items {
      image {
        gatsbyImageData
        alt
      }
      lifestyle_image {
        gatsbyImageData
        alt
      }
      brand {
        uid
        type
        link_type
        document {
          ... on PrismicBrand {
            data {
              name
              url
            }
          }
          ... on PrismicCategory {
            data {
              name
              url
            }
          }
        }
      }
      title
      text
    }
  }
`;
// product_url {
//   title
//   handle
// }
